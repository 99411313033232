.settings-page {
	margin-top: 9.6rem;
	height: 100%;
	padding-bottom: 10.6rem;
	overflow: hidden;

	&__inner {
		display: flex;
		flex-direction: column;
		height: 100%;
		background: $color-white;
		border-radius: 2px;
		margin: 1.5rem 1.5rem 0 1.5rem;
		padding: 1.6rem;
		overflow-y: auto;
	}

	&__settings-text {
		font-weight: 700;
		font-size: 1.8rem;
		letter-spacing: -0.02em;
		color: $color-dark-green;
		margin-bottom: 0.5rem;
	}

	&__settings-header {
		font-size: 1.6rem;
		line-height: 1.8rem;
		letter-spacing: -0.02em;
		color: $color-dark-green;

		&--space-bottom {
			margin-bottom: 2.1rem;
		}
	}

	&__settings-item {
		display: flex;
		align-items: center;
		font-size: 1.8rem;
		line-height: 1.8rem;
		letter-spacing: -0.02em;
		color: $color-dark-green;
		cursor: pointer;

		.icon-notif,
		.icon-settings {
			font-size: 3.5rem;
			border: 1px solid $color-dark-green;
			border-radius: 50%;
			min-width: 3.5rem;
		}
	}

	&__bottom-wrapper {
		margin: auto 0 0.7rem;
		font-size: 1.2rem;
		letter-spacing: -0.02em;
		color: $color-dark-green;
	}

	&__open-banking-wrapper {
		line-height: 1.8rem;
		letter-spacing: -0.02em;
		font-size: 1.8rem;
		display: flex;
		flex-direction: column;
		margin-bottom: 2rem;

		a {
			color: $color-brilliant-blue;
		}
	}

	&__notification-settings {
		margin-bottom: 9rem;
		overflow-y: auto;
	}

	&__joint-account {
		font-size: 1.8rem;
		letter-spacing: -0.02em;
		color: $color-dark-green;
		margin-bottom: 2rem;
	}

	&__checkbox-wrapper-outer {
		margin-bottom: 2rem;

		&--disabled {
			label,
			.icon-square {
				color: rgba(3, 69, 64, 0.5);
				cursor: not-allowed;
			}
		}
	}

	&__checkbox-wrapper {
		display: flex;
		align-items: center;
		margin-bottom: 0.5rem;
		font-size: 1.4rem;
		line-height: 1.8rem;
		letter-spacing: -0.02em;
		color: $color-dark-green;

		.container-checkbox {
			margin-left: auto;
		}
	}

	&__show-more-wrapper {
		display: flex;
		align-items: center;
		margin-top: 2rem;

		span {
			color: $color-red;
			font-weight: 500;
			letter-spacing: -0.02em;
			text-decoration-line: underline;
			margin-left: auto;
			cursor: pointer;
		}

		&--disabled {
			label {
				color: rgba(3, 69, 64, 0.5);
			}
		}
	}

	&__show-more-wrapper-outer {
		line-height: 1.8rem;
		letter-spacing: -0.02em;
		color: $color-dark-green;
		padding-top: 1rem;
		font-size: 1.4rem;

		ul {
			padding-left: 2rem;
		}
	}

	&__info-wrapper {
		background: $color-light-geyser;
		border: 1px solid $color-dark-green;
		border-radius: 2px;
		padding: 1.4rem;
		margin-top: 1.2rem;
		font-size: 1.4rem;
		line-height: 1.8rem;
		letter-spacing: -0.02em;
		color: $color-dark-green;

		.icon-info {
			font-size: 2rem;
			color: $color-red;
		}
	}

	&__icon-wrapper {
		display: flex;
		align-items: center;
		margin-bottom: 0.5rem;
		font-weight: 700;

		label {
			margin-left: 0.5rem;
		}
	}

	&__not {
		margin: 0 4px;
		text-decoration: underline;
	}

	&__text-wrapper {
		display: flex;
		flex-direction: column;
	}

	&__icon-arrow-wrapper {
		@include iconArrow;
	}

	&__user-wrapper {
		display: flex;
		flex-direction: column;
	}

	&__user-inner-wrapper {
		display: flex;
		align-items: center;
		margin-top: 2.1rem;
	}

	&__account-wrapper {
		display: flex;
		align-items: center;
		font-size: 1.6rem;
		line-height: 2.5rem;
		letter-spacing: -0.02em;
	}

	&__account-inner-wrapper {
		display: flex;
		flex-direction: column;
		color: $color-dark-green;

		span:first-child {
			font-weight: 700;
		}
	}

	&__content-wrapper {
		@include contentHiddenWrapper;
	}

	&__user-full-name {
		font-weight: 700;
		cursor: pointer;
	}

	&__details {
		margin-top: 9.6rem;
		display: flex;
		flex-direction: column;
		height: 100%;
		position: relative;

		.icon-checkmark-outline {
			font-size: 2.5rem;
			border: 1px solid;
			border-radius: 50%;
			padding: 0.2rem;
		}

		button {
			margin: 1rem 0 0 0 !important;
		}
	}

	&__body-wrapper {
		@include bodyWrapper;
	}

	&__content-information {
		div {
			margin-bottom: 2rem;
		}
	}

	&__expandable-item-component {
		display: flex;
		flex-direction: column;
	}

	&__no-users {
		font-size: 1.6rem;
		margin-top: 2.9rem;
		color: $color-dark-green;
	}

	&__no-enabled-nominated-wrapper {
		display: flex;
		align-items: center;
		padding-top: 0;
	}

	&__no-enabled-nominated-footer {
		display: flex;
		align-items: center;

		div {
			font-size: 1.4rem;
			line-height: 1.8rem;
			letter-spacing: -0.02em;
			color: $color-dark-green;
		}

		.icon-alert-circle {
			font-size: 2.5rem;
			color: $color-red;
			margin-right: 2rem;
			margin-bottom: 0.2rem;
		}
	}

	&__account-no-enabled-wrapper {
		display: flex;
		margin-bottom: 1.8rem;
		font-size: 1.4rem;
		color: $color-dark-green;

		span:last-child {
			font-weight: bold;
			margin-left: 2.3rem;
		}
	}

	button {
		margin: 1.5rem;
		width: auto;
	}

	&__open-banking-wrapper {
		margin-top: 20px;
		letter-spacing: -0.02em;
		font-size: 1.8rem;
	}

	&__open-banking-link-wrapper {
		margin-top: 2.5rem;
	}

	&__open-banking-link-wrapper label {
		display: block;
		margin-bottom: 0.5rem;
	}

	&__open-banking-link-wrapper a {
		font-size: 1.4rem;
		word-wrap: break-word;
	}

	&__nominated-content {
		margin-top: 2rem;
		font-size: 1.6rem;
		line-height: 1.8rem;
		letter-spacing: -0.02em;
		color: $color-dark-green;

		a {
			letter-spacing: -0.02em;
			color: $color-dark-green;
		}
	}

	&__nominated-to {
		margin: 0 0.3rem;
	}
}
