.bottom-navigation-bar {
	position: absolute;
    bottom: 0;
    left: 0;
    right: 0;		
	color: $color-dark-green;
    background: $background-content-color;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 4;
    width: 100%;
    padding: 1.1rem 1.2rem 1.1rem;

	@include respond(phone) {
		position: fixed;
		max-width: 100%;
	}

	&__inner-wrapper {
		background-color: $color-white;
		display: flex;
		justify-content: space-around;
		align-items: center;
		height: 7rem;
		width: 100%;
		border: 0.5px solid #003344;
		border-radius: 2px;
		z-index: 4;
	}

	&__icon {
		font-size: 3rem;
		color: $color-dark-green;
		border: 1px solid $color-dark-green;
		border-radius: 50%;
		min-width: 3rem;
		margin-bottom: 0.5rem;
	}


	a {
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		text-decoration: none;
		position: relative;

		&.is-active span {
			background-color: $color-yellow;
		}

		label {
			font-weight: 700;
			font-size: 1.4rem;
			letter-spacing: -0.02em;
			color: $color-dark-green;
		}
	}

	&__status-number {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: -0.5rem;
		right: 1.7rem;
		width: 2rem;
		height: 2rem;
		background-color: $color-red;
		color: $color-white;
		border-radius: 50%;
	}
}
