.error-page {
	&__container {
		margin: 11.2rem 1.6rem 0rem 1.6rem;
		height: 16.2rem;
		background-color: $color-white;
		box-shadow: 0rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
		border-radius: 0.2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__title {
		font-family: 'Lato';
		color: $color-red;
		font-size: 2.4rem;
		font-weight: bold;
		line-height: 2.2rem;
		text-align: center;
		letter-spacing: -0.02em;
		margin-bottom: 1.6rem;
		margin-top: 0.8rem;
	}

	&__description {
		width: 20.4rem;
		color: $color-dark-green;
		font-size: 1.4rem;
		font-weight: bold;
		line-height: 1.8rem;
		letter-spacing: -0.02em;
		text-align: center;
	}

	.icon-question-mark-circle,
	.icon-alert-triangle {
		color: $color-red;
		font-size: 4rem;
	}
}
