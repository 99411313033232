// FONT
$default-font-size: 1.6rem;

// COLORS
$background-header-color: #c4d600;
$background-content-color: #f4f2ec;
$background-footer-color: #dcd9cb;

// PRIMARY
$color-cyprus: #002d3c;
$color-calypso: #225568;
$color-calypso-vibrant: #007692;
$color-licorice: #000217;
$color-dark-green: #034540;
$color-dark-green-opacity-10: rgba(3, 69, 64, 0.1);
$color-dark-green-opacity-50: rgba(3, 69, 64, 0.5);
$color-dark-green-opacity-60: rgba(3, 69, 64, 0.6);
$color-yellow: #c4d600;
$color-red: #e06055;
$color-error: #ed3556;

// SECONDARY
$color-brilliant-blue: #0075dc;
$color-lochmara: #0060a7;
$color-cerulean: #00b3ff;
$color-anakiwa: #6ae5ff;

// ACCENT
$color-gossamer: #06bea6;
$color-shamrock: #20e47d;
$color-windsor: #4b00a0;
$color-heliotrope: #a506be;

$color-pompadour: #950050;
$color-cerise: #d63f5a;
$color-pumpkin: #f27d20;
$color-hokey-pokey: #fabe34;

// BASE
$color-black: #000000;
$color-white: #ffffff;
$color-porcelain: #f5f7f8;
$color-zircon: #eceff1;
$color-geyser: #cfd8dc;
$color-nepal: #b0bec5;
$color-casper: #90a4ae;

$color-nevada: #78909c;
$color-fjord: #607d8b;
$color-hoki: #4f6772;
$color-mirage: #41535b;
$color-mystic: #dde4e7;
$color-light-geyser: #EDEADF;

$color-status-green: #19d672;
$background-unread-color: #dce1db;
$color-status-blue: #0055d3;
$color-dark-silver: #dfdfe1;

