.error-session-page {
	&__content-wrapper {
		@include contentWrapper;
		padding: 0;
		margin-top: 9.6rem;
	}

	&__session-wrapper {
		display: flex;
		align-items: center;
		margin: 3.3rem 0 3rem 2.6rem;
	}

	&__session-timed-out {
		margin-left: 1.8rem;
		font-style: normal;
		font-weight: bold;
		font-size: 2.3rem;
		line-height: 2.7rem;
		color: #034540;
	}

	&__session-timed-out-logo {
		min-height: 4.1rem;
		min-width: 4.1rem;
		height: 4.1rem;
		width: 4.1rem;
	}

	&__content-text {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		margin: 0 3rem 0 4.7rem;
		font-style: normal;
		font-weight: normal;
		font-size: 1.4rem;
		line-height: 1.9rem;

		span {
			margin-bottom: 2rem;
			white-space: pre-wrap;
		}
	}
}
