.member-page {
	@include respond(phone) {
		max-height: initial !important;
	}

	.content-wrapper {
		overflow-x: hidden;
		overflow-y: auto;

		&__member-number {
			font-weight: 700;
		}
	}

	&__not-found {
		@include invalidInputError;
	}

	#input-memberId {
		&::placeholder {
			color: $color-dark-green;
		}
	}

	#date-of-birth {
		.input-container__wrapper .inner-div__label {
			opacity: 1;
			visibility: visible;
		}

		input {
			padding-top: 1.5rem;

			&::placeholder {
				color: $color-dark-green;
			}
		}

		.input-container__wrapper .inner-div.populated input {
			padding-top: 2.3rem;
		}

		.input-container__wrapper .inner-div.populated .inner-div__label {
			padding-top: 0;
			transform: none;
		}
	}
}
