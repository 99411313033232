.stop-sharing-page {
	&__content {
		padding: 2rem 1.6rem 0rem 1.6rem;
		min-height: 26.2rem;
	}

	&__shared-data-title {
		font-size: 1.8rem;
		line-height: 2.2rem;
		letter-spacing: -0.02em;
		color: $color-cyprus;
		margin-left: 0.4rem;
	}

	&__shared-data {
		display: flex;
		width: 100%;
		align-items: center;
		margin-bottom: 1.6rem;
		cursor: pointer;

		.icon-layers {
			color: $color-dark-green;
			font-size: 1.6rem;
		}
	}

	&__shared-data {
		margin-bottom: 1.8rem;
	}

	&__footer-question {
		font-family: Inter;
		font-style: normal;
		font-weight: 600;
		font-size: 1.4rem;
		line-height: 1.8rem;
		text-align: center;
		letter-spacing: -0.02em;
		color: $color-dark-green;
		width: 22rem;
		margin: auto;
	}

	&__footer-btns-container {
		display: flex;
		flex-direction: column;

		.btn-container:last-of-type {
			background-color: $background-content-color !important;
			margin-top: auto;
			color: $color-dark-green !important;
			border: 2px solid $color-dark-green;

			span {
				color: $color-dark-green !important;
				font-size: 1.8rem;
				line-height: 2.2rem;
				letter-spacing: -0.02em;
				font-weight: bold;
			}
		}

		.btn-container:first-of-type {
			background-color: $color-dark-green !important;
			margin-bottom: 0.8rem;
			margin-top: 1.6rem;
			font-size: 2rem;
			line-height: 2.2rem;

			span {
				color: $color-yellow !important;
				letter-spacing: -0.02em;
			}
		}
	}

	&__pop-up,
	&__first-modal-pop-up {
		overflow: hidden;
		visibility: hidden;
		transition: all 0.15s ease-out;
		display: flex;
		flex-direction: column;
		position: absolute;
		bottom: -20rem;

		&--visible {
			bottom: 0em;
			width: 100%;
			max-width: 38rem;
			transition: all 0.15s ease-out;
			overflow-y: auto;
			background: $color-white;
			box-shadow: 0px -6px 18px rgba(0, 45, 60, 0.2);
			border-radius: 8px;
			max-height: initial;
			z-index: 3;
			visibility: visible;
			padding: 2.4rem 1.6rem 2.4rem 1.6rem;
			outline: none;

			@include respond(phone) {
				max-width: 100%;
				border-radius: 8px 8px 0px 0px;
			}

			> div:first-of-type {
				display: flex;
				flex-direction: column;
				height: 100%;
			}
		}

		.btn-container {
			background-color: $color-dark-green !important;
			margin-bottom: 0.8rem;
			margin-top: 1rem;
			font-size: 1.8rem;
			line-height: 2.2rem;

			span {
				color: $color-yellow !important;
				letter-spacing: -0.02em;
				font-weight: bold;
			}

			.icon-loader-outline {
				font-size: 2.5rem;
				margin-top: 0.2rem;
			}
		}
	}

	&__pop-up {
		&--visible {
			height: 30rem;
		}
	}

	&__first-modal-pop-up {
		&--visible {
			height: 20rem;
		}
	}

	&__pop-up-wrapper,
	&__first-modal-pop-up-wrapper {
		@include popupWrapper;
	}

	&__pop-up-title {
		letter-spacing: -0.02em;
		margin-left: 0.4rem;
		color: $color-dark-green;
		font-weight: bold;
		font-size: 1.8rem;
		line-height: 2.2rem;
		margin-bottom: 5.5rem;
		width: 30rem;
	}

	&__pop-up-info {
		display: flex;
		margin-bottom: 1.8rem;
		align-items: center;

		div {
			font-weight: 600;
			font-size: 1.4rem;
			line-height: 1.8rem;
			letter-spacing: -0.02em;
			color: $color-dark-green;
		}

		.icon-checkmark-circle-2 {
			font-size: 2.2rem;
			color: $color-dark-green;
			margin-right: 0.4rem;
		}
	}

	&__pop-up-consent-withdrawal-confirmation {
		position: relative;
		background: $background-content-color;
		border: 0.1rem solid $color-dark-green;
		border-radius: 0.2rem;
		height: 4.8rem;
		padding: 1.9rem 1rem 1.9rem 1.9rem;
		display: flex;
		align-items: center;
		color: $color-dark-green;
		text-decoration: none;
		cursor: pointer;

		.icon-file {
			font-size: 2.3rem;
		}

		div {
			font-weight: bold;
			font-size: 1.4rem;
			line-height: 1.8rem;
			letter-spacing: -0.02em;
			margin-left: 0.8rem;
			padding-right: 2rem;
		}

		.icon-loader-outline {
			font-size: 2.4rem;

			&.rotating {
				position: absolute;
				right: 0.7rem;
				top: 1rem;
			}
		}

		.icon-download-outline {
			font-size: 2.4rem;
			cursor: pointer;
		}

		span:last-child {
			margin-left: auto;
		}
	}
}
