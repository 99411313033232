.notifications-page {
	margin-top: 9.6rem;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: 100%;
	margin-bottom: 13rem;

	&__details {
		margin-top: 9.6rem;
		display: flex;
		flex-direction: column;
		height: 100%;
		position: relative;

		.icon-checkmark-outline {
			font-size: 2.5rem;
			border: 1px solid;
			border-radius: 50%;
			padding: 0.2rem;
		}

		.icon-loader-outline {
			font-size: 3rem;
			max-width: 40px;
			top: 5rem;

			&.rotating {
				position: absolute;
			}
		}
	}

	&__body {
		word-wrap: break-word;
	}

	&__consent-title {
		font-size: 1.6rem;
		letter-spacing: -0.02em;
		color: $color-dark-green;
		width: 100%;
	}

	&__notification-wrapper {
		display: flex;
		flex-direction: column;
	}

	&__notification {
		display: flex;
		background: $color-white;
		border-radius: 10px;
		margin: 0.5rem 1.3rem;
		cursor: pointer;
		padding: 1rem 0 1rem 1.3rem;

		&.bold {
			background-color: $background-unread-color;
		}
	}

	&__pending-approval-wrapper {
		display: flex;

		.icon-alert-circle {
			font-size: 2.5rem;
			color: $color-red;
			margin-right: 1rem;

			&--inactive {
				color: rgba(57, 114, 108, 0.6);
			}
		}
	}

	&__manage,
	&__view {
		margin-top: auto;
		margin-bottom: auto;
		margin-left: auto;
	}

	.icon-arrow-ios-forward-outline {
		margin-right: 1.6rem;
		margin-top: auto;
		margin-bottom: auto;
		margin-left: auto;
		font-size: 2.2rem;
	}

	&__splitter {
		border: 0.1rem solid rgba(3, 69, 64, 0.5);
		margin: 0 1.4rem;
	}

	&__body-wrapper {
		@include bodyWrapper;
	}

	&__body-title {
		font-weight: 600;
		margin-bottom: 1.5rem;
	}

	&__info-bar {
		position: absolute;
		bottom: 9.2rem;
		left: 1.2rem;
		right: 1.2rem;
		color: $color-dark-green;
		font-weight: 300;
		font-size: 14px;
		line-height: 22px;
		letter-spacing: -0.02em;
		background-color: $color-white;
		display: flex;
		align-items: center;
		height: 3rem;
		border-radius: 5px;
		border: 0.5px solid $color-dark-green;

		.icon-alert-circle {
			font-size: 2.5rem;
			color: $color-red;
			margin-left: 0.6rem;
		}

		label {
			margin-left: 0.4rem;
		}
	}

	&__buttons-wrapper {
		margin: 1rem 2rem 10rem;
	}

	&__notification-information {
		div {
			margin-bottom: 2rem;
		}
	}

	&__account-name {
		font-weight: 600;
	}
}
