.description-component {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 15.2rem;
	min-height: 15.2rem;
	width: 100%;
	min-width: 100%;
	background-color: $color-dark-green;
	margin-top: 9.6rem;

	&-title,
	&-company-name-wrapper {
		font-family: 'Lato', sans-serif;
		font-size: 2.4rem;
		line-height: 2.2rem;
		letter-spacing: -0.02rem;
		color: $background-header-color;
	}

	&-connect-to {
		margin-right: 0.5rem;
	}

	&-company-name-wrapper {
		display: flex;
	}

	&-content {
		font-family: 'Inter', sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 1.4rem;
		line-height: 1.8rem;
		color: $background-content-color;
		text-align: center;
		margin-top: 0.8rem;
		letter-spacing: -0.02rem;
		padding: 0 2.5rem;
	}

	&__top-title {
		margin-top: 0.5rem;
		margin-bottom: 1.8rem;
	}
}
