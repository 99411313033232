.cancel-pop-up {
	overflow: hidden;
	visibility: hidden;
	transition: all 0.3s ease-out;
	display: flex;
	flex-direction: column;
	position: absolute;
	bottom: -20rem;

	&--visible {
		display: flex;
		flex-direction: column;
		position: absolute;
		bottom: 0rem;
		width: 100%;
		max-width: 38rem;
		transition: all 0.3s ease-out;
		overflow-y: auto;
		background: $color-white;
		box-shadow: 0px -6px 18px rgba(0, 45, 60, 0.2);
		border-radius: 8px;
		max-height: initial;
		z-index: 3;
		visibility: visible;
		padding: 2.4rem 1.6rem;
		outline: none;

		@include respond(phone) {
			max-width: 100%;
			border-radius: 8px 8px 0px 0px;
		}
	}

	&__wrapper {
		@include popupWrapper;
	}

	&__title {
		font-weight: bold;
		font-size: 1.8rem;
		line-height: 2.2rem;
		letter-spacing: -0.02rem;
		color: $color-dark-green;
		margin-bottom: 2.4rem;
	}

	&__description {
		display: flex;
		margin-bottom: 3.2rem;

		.href-icon-wrapper {
			margin-top: 0rem;
		}
	}

	&__description-wrapper {
		margin-left: 0.4rem;
	}

	&__description-header {
		font-weight: 600;
		font-size: 1.4rem;
		line-height: 1.8rem;
		letter-spacing: -0.02rem;
		color: $color-dark-green;
	}

	&__description-content {
		font-size: 1.4rem;
		line-height: 1.8rem;
		letter-spacing: -0.02rem;
		color: $color-dark-green;
		margin-top: 0.4rem;
	}

	.icon-question-mark-circle {
		color: $color-red;
		font-size: 1.6rem;
		margin-top: 0.1rem;
	}

	&__btns-container {
		display: flex;
		margin-top: auto;

		button:first-of-type {
			margin-right: 0.9rem;
		}
	}

	.btn-container {
		background-color: $color-dark-green;

		span {
			color: $color-yellow;
			font-size: 1.8rem;
			line-height: 2.2rem;
			letter-spacing: -0.02em;
		}

		&:disabled {
			background-color: $color-dark-green-opacity-50 !important;

			span {
				color: $color-dark-green-opacity-50 !important;
			}
		}

		&:hover {
			background-color: $color-dark-green;
		}
		&:active {
			background-color: $color-dark-green;
			color: $color-yellow;
		}
	}
}

.ReactModalPortal {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	background: #e8e6df;
}
