.consents-page {
	.content-wrapper {
		margin-top: 14.6rem;
	}

	&__content {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		height: 100%;
		padding: 2.4rem 1.6rem 0.8rem;
		margin-bottom: 9rem;
		margin-top: 15.2rem;
	}

	&__buttons {
	    width: 100%;
		display: flex;
		margin-top: 9.6rem;
		position: fixed;
		align-items: center;
		max-width: 38rem;
		justify-content: center;
		z-index: 2;

		@include respond(phone) {
			max-width: 100%;
			border: none;
		}
	}

	&__active,
	&__archived {
		width: 100%;
		height: 5.6rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $color-light-geyser;
		cursor: pointer;

		.icon-archive,
		.icon-checkmark-circle-outline {
			color: $color-dark-green;
			font-size: 2rem;
			margin-right: 0.6rem;
		}

		&--selected {
			background-color: $color-dark-green;

			@include respond(phone) {
				height: 5.5rem;
			}

			.icon-archive,
			.icon-checkmark-circle-outline {
				color: $background-header-color;
			}
		}
	}

	&__logo-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 4rem;
		width: 4rem;
		height: 4rem;

		img {
			width: 100%;
		}
	}

	&__account-wrapper {
		display: flex;
	}

	&__buttons-text {
		font-size: 1.8rem;
		line-height: 2.2rem;
		letter-spacing: -0.02rem;
		color: $color-dark-green;

		&--selected {
			color: $background-header-color;
		}
	}

	&__consent {
		display: flex;
		flex-direction: column;
		margin-bottom: 0.8rem;
		background: $color-white;
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
		border-radius: 2px;
		padding: 1.1rem 2rem 1.1rem 1.2rem;
		cursor: pointer;

		&--partial {
			background: $color-dark-green-opacity-10;
		}
	}

	&__consent-inner {
		display: flex;
		width: 100%;

		&:last-child {
			margin-top: 0.8rem;
			margin-bottom: 0.8rem;
		}
	}

	&__account-display-name {
		font-weight: normal;
	}

	&__content-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		font-size: 1.4rem;
		line-height: 1.8rem;
		letter-spacing: -0.02rem;
		margin-left: 1.1rem;
		font-weight: bold;
		color: $color-dark-green;
	}

	&__manage-wrapper {
		display: flex;
		align-items: center;
		margin-left: auto;
		font-weight: 600;
		font-size: 1.4rem;
		line-height: 1.8rem;
		letter-spacing: -0.02rem;
		color: $color-dark-green;
		cursor: pointer;

		.icon-arrow-ios-forward {
			font-size: 2.2rem;
		}

		span {
			margin-bottom: 0.5rem;
			margin-right: 0.2rem;
		}
	}

	&__text-wrapper {
		display: flex;
		flex-direction: column;
		margin-left: auto;
		font-size: 1.4rem;
		line-height: 1.8rem;
		letter-spacing: -0.02rem;

		div {
			color: $color-dark-green;
		}
	}

	&__joint-text {
		@include jointText;
	}
}
