.accounts-page {
	margin-top: 9.6rem;
	display: flex;
	flex-direction: column;
	height: 100%;

	&__content {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		height: 100%;
		padding: 2.4rem 1.6rem 0.8rem;
		margin-bottom: 9rem;
	}

	&__content-top {
		width: 100%;
		display: flex;
		align-items: center;
	}

	&__content-wrapper {
		@include contentHiddenWrapper;

		&--visible {
			margin-top: 0;
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
		height: 85%;
		background: $color-white;
		border-radius: 2px;
		margin: 1.5rem;
	}

	&__account-name-number {
		display: flex;
		flex-direction: column;
		margin: 2.4rem 0 0 1.6rem;
		
		span:first-child {
			font-weight: 700;
			font-size: 1.8rem;
			line-height: 1.8rem;
			letter-spacing: -0.02em;
			color: $color-dark-green;
		}

		span:last-child {
			font-weight: 400;
			font-size: 1.4rem;
			line-height: 1.8rem;
			margin-top: 0.7rem;
			letter-spacing: -0.02em;
			color: $color-dark-green;
		}
	}

	&__content-text {
		display: flex;
		align-items: center;
		font-weight: 700;
		font-size: 1.8rem;
		letter-spacing: -0.02em;
		color: $color-dark-green;
		margin: 1.4rem 0 1.2rem 1.6rem;

		span:last-child {
			margin-left: 1.1rem;
		}
	}

	&__checkbox-wrapper {
		display: flex;
		align-items: center;
		width: 100%;
		margin-top: 1rem;
		margin-bottom: 1.6rem;

		.container-checkbox {
			margin-left: auto;
			margin-right: 4rem;

			&:disabled {
				cursor: not-allowed;
			}
		}
	}
	
	&__held-text {
		font-weight: 400;
	}

	&__accounts-text {
		font-size: 1.8rem;
		line-height: 2.2rem;
		color: $color-dark-green;
		letter-spacing: -0.02em;
		margin-left: 0.4rem;
		font-weight: bold;
	}

	&__joint-accounts {
		margin-bottom: 1.6rem;
	}

	&__available-for-sharing, &__unavailable-for-sharing {
		margin-top: 0.8rem;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		padding: 0.8rem 1.2rem;
		font-size: 1.4rem;
		line-height: 1.8rem;
		border-radius: 2px;

		div {
			display: flex;
			flex-direction: column;
		}

		.icon-question-mark-circle {
			margin-left: 1.2rem;
			color: $color-red;
			font-size: 2.5rem;
			cursor: pointer;
		}
	}

	&__available-for-sharing {
		background-color: $color-white;
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
	}

	&__unavailable-for-sharing {
		background: transparent;
		border: 1px solid rgba(57, 114, 108, 0.6);
		box-shadow: none;
	}

	&__unavailable-for-sharing &__accounts-name, 
	&__unavailable-for-sharing &__balance {
		color: $color-dark-green-opacity-60;
	}

	&__account-info-wrapper {
		margin: 1.8rem 0 2.4rem 0;

		div {
			span:first-child {
				font-weight: 700;
				font-size: 18px;
				line-height: 22px;
			}

			span:last-child {
				font-size: 1.4rem;
				line-height: 1.8rem;
				letter-spacing: -0.02em;
			}
		}
	}

	&__accounts-name {
		display: flex;
		flex-direction: column;
		color: $color-dark-green;

		span:first-child {
			font-weight: 700;
		}
	}

	&__balance {
		margin-left: auto;
		align-items: flex-end;
		color: $color-dark-green;
	}

	&__balance-currency {
		font-weight: bold;
	}

	&__individual-accounts-wrapper {
		margin-top: 1.6rem;

		&--rotated {
			margin-bottom: 1.6rem;
		}
	}

	&__icon-arrow-wrapper {
		@include iconArrow;
	}

	.icon-alert-circle{
		color: $color-red;
		font-size: 3.5rem;
	}

	.icon-checkmark-outline {
		font-size: 2.5rem;
		border: 1px solid;
		border-radius: 50%;
		padding: 0.2rem;
	}

	&__buttons-wrapper{
		margin: 0 2rem 10rem;
	}

	&__confirmed-account-wrapper {
		font-size: 1.4rem;
		line-height: 1.8rem;
		letter-spacing: -0.02em;
		color: $color-dark-green;
		padding: 1.4rem 1rem 1.5rem;

		div {
			margin-bottom: 2rem;
		}
	}

	&__select-dots {
		cursor: pointer;
		margin-left: 1.2rem;
	}

	&__header-text {
		margin-right: 1.2rem;
	}

	&__request-pending {
		display: flex;
		margin: 0 0 1.5rem 1.6rem;

		.icon-alert-circle {
			font-size: 4rem;
		}
	}

	&__request-pending-inner {
		display: flex;
		flex-direction: column;
		margin-left: 1.1rem;
		font-size: 1.6rem;
		line-height: 1.8rem;
		letter-spacing: -0.02em;
		color: $color-dark-green;

		label:first-child {
			font-weight: 600;
		}
	}

	&__inner--pending &__account-name-number {
		span {
			color: rgba(3, 69, 64, 0.5);
		}
	}

	&__inner--pending &__held-text {
		color: rgba(3, 69, 64, 0.5);
	}

	&__inner--pending &__checkbox-wrapper {
		label, span {
			color: rgba(3, 69, 64, 0.5);
		}
	}

	&__pop-up-question-wrapper {
		margin-left: 0.4rem;
	}

	&__pop-up {
		@include popUpModal;
	}

	&__unavailable-for-sharing-wrapper {
		display: flex;
		align-items: center;
		color: $color-fjord;
		font-weight: 600;
		font-size: 1.4rem;
		line-height: 1.8rem;
		letter-spacing: -0.02em;

		span:last-child {
			margin-left: 0.2rem;
		}

		.icon-inbox-outline {
			font-size: 2rem;
		}
	}

	&__pop-up-meet-the-eligibility {
		margin-left: 1.8rem;
	}

	&__pop-up-meet-the-eligibility &__pop-up-question-content {
		margin-left: 1rem;
		
		&:before {
			content:"• ";
		}

		&:last-child {
			margin-top: 2rem;
			margin-left: 0;

			&:before {
				content: none;
			}	
		}
	}

	&__pop-up-question-icon {
		display: flex;
		margin-bottom: 1.6rem;
	}
	
	&__pop-up-question-header {
		@include popUpQuestionHeader;
	}

	&__pop-up-question-content {
		@include popUpQuestionContent;
	}

	&__pop-up-question-business-content {
		@include popUpQuestionContent;
		margin-top: 1rem;	
	}

	&__pop-up-question-business-content-text-bold {
		@include popUpQuestionContent;
		font-weight: bold;
		margin-top: 1rem;	
	}

	&__pop-up-question-business-content-text {
		@include popUpQuestionContent;
		margin-top: 0;
	}

	&__pop-up-do-not-share {
		margin-left: 0.5rem;
	}

	&__no-available-accounts {
		display: flex;
		flex-direction: column;
		border: 1px solid $color-dark-green;
		border-radius: 0.2rem;
		padding: 1.2rem;
		color: $color-dark-green;
		letter-spacing: -0.02em;
		margin-top: 0.8rem;
	}

	&__no-available-accounts-header {
		font-weight: 700;
		line-height: 1.8rem;
		font-size: 1.4rem;
	}

	&__no-available-accounts-content {
		line-height: 1.8rem;
		font-size: 1.4rem;
		margin-top: 0.5rem;
	}
}
