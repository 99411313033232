.consent-details-page {
	margin-top: 9.6rem;
	margin-bottom: 10rem;
	overflow-y: auto;
	z-index: 3;

	&__header {
		height: 9.6rem;
		min-height: 9.6rem;
		background-color: $color-dark-green;
		margin-bottom: 1.6rem;
		display: flex;
		padding: 1.6rem 1.8rem;

		img {
			width: 6.4rem;
			height: 6.4rem;
			margin-right: 1.6rem;
			border-radius: 5%;
			padding: 0.5rem;
			background-color: $color-white;
		}
	}

	&__content-wrapper {
		display: flex;
		flex-direction: column;

		label:last-child {
			font-weight: 400;
		}
	}

	&__header-name-wrapper {
		padding: 1rem 0rem;
		color: $color-white;
	}

	&__status-wrapper {
		padding: 1rem 0rem;
		margin-left: auto;
		font-size: 1.4rem;
		line-height: 1.8rem;
		letter-spacing: -0.02em;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;
	}

	&__sharing-conditions {
		padding: 2rem 1.6rem 0.8rem 1.6rem;
	}

	&__sharing-conditions-history {
		padding: 2rem 1.6rem 0rem 1.6rem;
	}

	&__header-name {
		font-weight: bold;
		font-size: 1.8rem;
		line-height: 2.2rem;
		letter-spacing: -0.02em;
	}

	&__header-type {
		font-size: 1.4rem;
		line-height: 1.8rem;
		letter-spacing: -0.02em;
		color: #ffffff;
	}

	&__header-status-label {
		color: #ffffff;
	}

	&__header-status-value {
		font-weight: bold;
		color: $color-yellow;
	}

	&__header-status--cancelled {
		color: #eaa524;
	}

	&__sharing-condition {
		display: flex;
		width: 100%;
		align-items: center;
		margin-bottom: 1.6rem;
		cursor: pointer;

		.icon-inbox,
		.icon-calendar,
		.icon-layers,
		.icon-profile {
			color: $color-dark-green;
			font-size: 1.6rem;
		}

		.icon-info {
			color: $color-red;
			font-size: 1.6rem;
		}
	}

	&__sharing-conditions-title {
		font-size: 1.8rem;
		line-height: 2.2rem;
		letter-spacing: -0.02em;
		color: $color-cyprus;
		margin-left: 0.4rem;
	}

	&__sharing-condition-item {
		border-left: 0.2rem solid $color-dark-green;
		border-radius: 1px;
		margin-top: 1.2rem;
	}

	&__sharing-condition-item-title {
		margin-left: 0.8rem;
		font-weight: bold;
		font-size: 1.4rem;
		line-height: 1.8rem;
		letter-spacing: -0.02em;
		color: $color-dark-green;
		margin-bottom: 0.2rem;
	}

	&__sharing-condition-item-content,
	&__transaction-details-content {
		margin-left: 0.8rem;
		font-size: 1.4rem;
		line-height: 1.8rem;
		letter-spacing: -0.02em;
		color: $color-dark-green;
	}

	&__transaction-details-content {
		font-style: italic;
		margin-bottom: 0.3rem;
		margin-top: 0.3rem;
	}

	&__sharing-condition-item-additional-info-title {
		font-weight: bold;
		font-size: 1.4rem;
		line-height: 1.8rem;
		letter-spacing: -0.02em;
		color: $color-dark-green;
		margin-bottom: 0.2rem;
	}

	&__sharing-condition-item-additional-info-content {
		font-size: 1.4rem;
		line-height: 1.8rem;
		letter-spacing: -0.02em;
		color: $color-dark-green;
	}

	&__sharing-condition-item-link {
		display: block;
		color: $color-brilliant-blue;
		letter-spacing: -0.04em;
	}

	&__sharing-conditions-details {
		background-color: $color-white;
		box-shadow: 0rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
		border-radius: 2px;
		max-height: 100%;
		margin-top: -1.3rem;
		margin-bottom: 2.4rem;
		padding: 1.2rem;

		div:first-child .consent-details-page__sharing-condition-item:first-child {
			margin-top: 0;
		}
	}

	&__dates-sharing {
		.consent-details-page__sharing-condition-item {
			margin-bottom: 1.2rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__account-item-wrapper {
		margin-top: 0;
	}

	&__held-with-other {
		font-size: 1.4rem;
		line-height: 1.8rem;
		letter-spacing: -0.02em;
		color: $color-dark-green;
	}

	&__held-with-other-wrapper {
		display: flex;
		align-items: center;
		margin: 1rem 0 0 1rem;

		.icon-question-mark-circle {
			color: $color-red;
			font-size: 2rem;
			margin-left: auto;
		}
	}

	.btn-container {
		background-color: $color-dark-green;
		min-height: 4.8rem;

		@media only screen and (max-width: 320px) {
			width: 29rem;
		}

		span {
			color: $color-yellow;
			font-size: 1.8rem;
			line-height: 2.2rem;
			letter-spacing: -0.02em;
		}

		&:hover {
			background-color: $color-dark-green;
		}
		&:active {
			background-color: $color-dark-green;
			color: $color-yellow;
		}
	}

	&__confirmation-of-consent {
		position: relative;
		background: $color-white;
		border: none;
		min-height: 6.2rem;
		border-radius: 0.2rem;
		margin: 1rem 1.6rem 0rem 1.6rem;
		padding: 1.2rem;
		display: flex;
		align-items: center;
		color: $color-dark-green;
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
		cursor: pointer;
		text-decoration: none;

		.icon-file {
			font-size: 2.3rem;
		}

		div {
			font-size: 1.4rem;
			line-height: 1.8rem;
			letter-spacing: -0.02em;
			margin-left: 0.8rem;

			label:first-child {
				font-weight: bold;
			}
		}

		.icon-loader-outline {
			font-size: 2.2rem;

			&.rotating {
				position: absolute;
				right: 1.8rem;
				top: 2rem;
			}
		}

		.icon-download-outline {
			font-size: 2.2rem;
			cursor: pointer;
			margin-left: auto;
		}

		span:last-child {
			margin-left: auto;
		}
	}

	#stop-sharing-top-button,
	#stop-sharing-bottom-button {
		margin-left: 0;
	}

	&__consent-withdrawal-confirmation {
		color: $color-red;
		margin-top: 0.8rem;
		cursor: pointer;
	}

	&__pop-up {
		@include popUpModal;
	}

	&__pop-up-title {
		letter-spacing: -0.02em;
		margin-left: 0.4rem;
		color: $color-dark-green;
		font-weight: bold;
		font-size: 1.8rem;
		line-height: 2.2rem;
		margin-bottom: 2.4rem;
		width: 30rem;
	}

	&__pop-up-question-icon {
		display: flex;
		margin-bottom: 1.6rem;
	}

	&__pop-up-question-wrapper {
		margin-left: 0.4rem;
	}

	&__pop-up-question-header {
		@include popUpQuestionHeader;
	}

	&__pop-up-question-content {
		@include popUpQuestionContent;
	}

	&__pop-up-consent-info {
		display: flex;
		margin-bottom: 1.8rem;
		align-items: center;

		img {
			width: 2rem;
			height: 2rem;
			border-radius: 50%;
			margin-right: 0.3rem;
		}

		> div {
			font-size: 1.4rem;
			font-weight: 600;
			line-height: 1.8rem;
			letter-spacing: -0.02rem;
			color: $color-dark-green;
			margin-left: 0.4rem;
		}
	}

	&__archived-auth-subtitle {
		font-size: 1.4rem;
		line-height: 1.6rem;
		text-align: center;
		letter-spacing: -0.02em;
		color: $color-dark-green;
		display: flex;
		align-items: center;
		padding: 0.8rem 3rem;
		border: 0.1rem solid $color-geyser;
		border-radius: 0.2rem;
		margin: 0rem 1.6rem 0.6rem 1.6rem;
	}

	&__button-wrapper {
		padding: 0 1.6rem;
	}

	#stop-sharing-top-button,
	#stop-sharing-bottom-button {
		margin-left: 0;
	}

	&__account-wrapper {
		display: flex;
	}

	&__joint-text {
		@include jointText;
	}

	&__what-is-open-banking-content {
		display: flex;
		flex-direction: column;
		margin-bottom: 1rem;
	}

	&__disabled-switch {
		@include switchComponent;
		background-color: $color-dark-green;
		color: $color-white;
	}

	&__disabled-account-wrapper {
		font-size: 1.4rem;
		line-height: 1.8rem;
		letter-spacing: -0.02rem;
		color: $color-dark-green;
		margin-top: 0.4rem;

		.icon-question-mark-circle {
			font-size: 2rem;
			margin-right: 2rem;
		}
	}

	&__content-information {
		div {
			margin-top: 1rem;
			margin-bottom: 2rem;
		}
	}

	&__footer-information {
		margin-top: 5rem;
		margin-bottom: 2rem;
	}

	&__popup-header-component {
		border-bottom: 1px solid $color-dark-green;
	}

	&__icon-wrapper {
		display: flex;
		font-weight: 600;
		align-items: center;

		&__content-label {
			font-weight: 400;
		}

		.icon-alert-circle {
			font-size: 2.5rem;
			color: $color-red;
			margin-right: 2rem;
			margin-bottom: 0.2rem;

			&--inactive {
				color: rgba(57, 114, 108, 0.6);
			}
		}
	}

	&__held-with-other-modal {
		margin-top: 2rem;
		margin-bottom: 1rem;
	}
}
