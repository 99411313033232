// MEDIA QUERY MANAGER
@mixin respond($breakpoint) {
	@if $breakpoint == phoneXs {
		@media only screen and (max-width: 480px) {
			@content;
		}
	}
	@if $breakpoint == phone {
		@media only screen and (max-width: 767px) {
			@content;
		}
	}
	@if $breakpoint == phoneLandscape {
		@media only screen and (max-height: 400px) {
			@content;
		}
	}
	@if $breakpoint == tabletOnly {
		@media only screen and (min-width: 768px) and (max-width: 992px) {
			@content;
		}
	}
	@if $breakpoint == iPadProLandscape {
		@media only screen and (height: 1024px) and (width: 1366px) {
			@content;
		}
	}
	@if $breakpoint == iPadProPortrait {
		@media only screen and (max-width: 1024px) and (max-height: 1366px) {
			@content;
		}
	}
	@if $breakpoint == iPad {
		@media only screen and (max-width: 1024px) and (max-height: 768px) {
			@content;
		}
	}
	@if $breakpoint == tablet {
		@media only screen and (max-width: 992px) {
			@content;
		}
	}
	@if $breakpoint == desktop {
		@media only screen and (min-height: 781px) and (min-width: 1280px) {
			@content;
		}
	}
	@if $breakpoint == laptop {
		@media only screen and (max-width: 1280px) {
			@content;
		}
	}
	@if $breakpoint == laptopRegularScreenWidth {
		@media only screen and (max-height: 1100px) and (max-width: 1540px) {
			@content;
		}
	}
	@if $breakpoint == bigDesktop {
		@media only screen and (min-width: 1800px) and (min-height: 1080px) {
			@content;
		}
	}
}

@mixin icons {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin whiteCircle {
	width: 3.2rem;
	height: 3.2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $color-white;
	z-index: 1;
	margin-left: 1.8rem;
	cursor: pointer;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
	border-radius: 50%;
}

@mixin contentWrapper {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 1.6rem;
	background-color: $background-content-color;
}

@mixin popupWrapper {
	position: fixed;
	overflow: hidden;
	visibility: hidden;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	max-width: 38rem;
	transition: all 0.3s ease-out;
	border-radius: 8px;

	@include respond(phone) {
		max-width: 100%;
		top: 0;
		bottom: 0;
		border-radius: 0;
	}

	&--visible {
		visibility: visible;
		transition: all 0.3s ease-out;
		overflow-y: auto;
		max-height: 100vh;
		width: 100%;
		z-index: 9999;
		background: $color-dark-green;
		-webkit-backdrop-filter: blur(4px);
		backdrop-filter: blur(4px);
		max-height: 82rem;
		margin: auto;
		height: 100vh;
		height: -webkit-fill-available;

		@include respond(phone) {
			max-height: initial;
		}

		@include respond(phoneLandscape) {
			height: auto;
		}

		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			margin: 0 auto auto auto;
		}
	}
}

@mixin iconArrowIosUpDown {
	@include whiteCircle;
	margin-left: auto;

	&--up {
		margin-bottom: 0rem;

		.icon-arrow-ios-downward {
			transform: rotateX(180deg);
			margin-top: 0rem;
			margin-bottom: 0.1rem;
		}
	}
}

@mixin invalidInputError {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid $color-red;
	border-radius: 0.2rem;
	color: $color-red;
	min-height: 3.2rem;
	font-size: 1.4rem;
	line-height: 1.8rem;
	letter-spacing: -0.02em;
	padding: 0.7rem 1.4rem;
	margin-bottom: 1.5rem;
	margin-top: -1rem;
	font-weight: bold;
	background: transparent;
	opacity: 1;
	outline: none;
	text-align: center;
}

@mixin jointText {
	font-weight: bold;
	letter-spacing: -0.02em;
	color: $color-dark-green;
	margin-left: 0.4rem;
}

@mixin popUpModal {
	overflow: hidden;
	visibility: hidden;
	transition: all 0.15s ease-out;
	display: flex;
	flex-direction: column;
	position: absolute;
	bottom: -20rem;

	&--visible {
		bottom: 0em;
		width: 100%;
		max-width: 38rem;
		transition: all 0.15s ease-out;
		overflow-y: auto;
		background: $color-white;
		box-shadow: 0px -6px 18px rgba(0, 45, 60, 0.2);
		border-radius: 8px;
		max-height: initial;
		z-index: 9999;
		visibility: visible;
		padding: 2.4rem 1.6rem 2.4rem 1.6rem;
		outline: none;

		@include respond(phoneLandscape) {
			height: 100%;
		}

		@include respond(phone) {
			max-width: 100%;
			border-radius: 8px 8px 0px 0px;
		}

		> div:first-of-type {
			display: flex;
			flex-direction: column;
			height: 100%;
		}
	}

	&-wrapper {
		@include popupWrapper;
	}

	.icon-question-mark-circle,
	.icon-info {
		font-size: 1.6rem;
		color: $color-red;
	}

	.btns-container {
		display: flex;
		margin-top: auto;
	}

	.btn-container:first-of-type {
		background-color: $background-content-color;
		margin-top: auto;
		color: $color-dark-green;
		border: 2px solid $color-dark-green;
		margin-right: 0.9rem;

		span {
			color: $color-dark-green;
			font-size: 1.8rem;
			line-height: 2.2rem;
			letter-spacing: -0.02em;
			font-weight: bold;
		}

		&:hover {
			background-color: $color-white;
		}
		&:active {
			background-color: $color-white;
			color: $color-dark-green;
		}
	}

	.btn-container:last-of-type {
		background-color: $color-dark-green;

		span {
			color: $color-yellow;
			font-size: 1.8rem;
			line-height: 2.2rem;
			letter-spacing: -0.02em;
			font-weight: bold;
		}

		&:hover {
			background-color: $color-dark-green;
		}
		&:active {
			background-color: $color-dark-green;
			color: $color-yellow;
		}
	}
}

@mixin popUpQuestionHeader {
	font-weight: 600;
	font-size: 1.4rem;
	line-height: 1.8rem;
	letter-spacing: -0.02rem;
	color: $color-dark-green;
}

@mixin popUpQuestionContent {
	font-size: 1.4rem;
	line-height: 1.8rem;
	letter-spacing: -0.02rem;
	color: $color-dark-green;
	margin-top: 0.4rem;
}

@mixin iconLoading {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 3rem;
	margin-left: auto;
	margin-right: auto;

	.icon-loader-outline {
		font-size: 4rem;
		color: $color-dark-green;
	}
}

@mixin iconArrow {
	min-width: 3.2rem;
	width: 3.2rem;
	height: 3.2rem;
	background: $color-white;
	border-radius: 2.4rem;
	margin-left: auto;
	margin-right: 0.8rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border: solid 1px $color-dark-green;
	outline: none;
	transition: all 0.15s ease-out;
	cursor: pointer;

	.icon-arrow-ios-downward-outline {
		font-size: 2.5rem;
	}

	&--rotated {
		transition: all 0.15s ease-out;
		transform: rotate(180deg);
	}
}

@mixin contentHiddenWrapper {
	visibility: hidden;
	overflow: hidden;
	height: 0;
	max-height: 0;

	&--visible {
		visibility: visible;
		overflow: visible;
		height: auto;
		max-height: initial;
		transition: all 0.15s ease-out;
		margin-top: 2rem;
	}
}

@mixin bodyWrapper {
	font-family: 'Inter';
	display: flex;
	flex-direction: column;
	height: 85%;
	background: $color-white;
	border-radius: 10px;
	padding: 2.7rem 1.6rem 1.2rem 1.7rem;
	margin: 0.7rem 1.5rem;
	font-size: 1.7rem;
	line-height: 2.2rem;
	letter-spacing: -0.02em;
	color: $color-dark-green;
	white-space: pre-wrap;
}

@mixin switchComponent {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin-left: auto;
	width: 13rem;
	min-width: 13rem;
	background: $color-white;
	border: 0.5px solid $color-dark-green;
	border-radius: 20px;
	color: $color-dark-green;
	font-weight: 400;
	padding: 0.2rem;
	height: 2.2rem;
	line-height: initial;
}

@mixin iconLoader {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	font-size: 3rem;
	height: 3rem;
}

@mixin textOverflow {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* basic components */

@mixin inputLabel {
	font-size: 1.2rem;
	letter-spacing: 0.05rem;
	color: $color-casper;
}

@mixin text {
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 1.6rem;
}

@mixin inputTooltip {
	position: absolute;
	text-align: center;
	min-width: 13.4rem;
	height: auto;
	width: fit-content;
	opacity: 0;
	visibility: hidden;
	margin-bottom: 1em;
	padding: 1em;
	background: $color-white;
	box-shadow: 0px 2px 3px rgba(37, 48, 69, 0.25);
	border-radius: 4px;
	text-transform: uppercase;
	font-size: 1rem;
	line-height: 1.6rem;
	letter-spacing: 1px;
	color: $color-mirage;
	transform: translate(-50%, 1em);
	transition: all 0.15s ease-in-out;
	z-index: 9999;
	font-family: 'Inter';

	&::before {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		width: 0;
		height: 0;
		border: 0.5em solid transparent;
		border-top-color: $color-white;
		transform: translate(-50%, 0);
	}
}

@mixin inputTooltip {
	position: absolute;
	text-align: center;
	min-width: 13.4rem;
	height: auto;
	width: fit-content;
	opacity: 0;
	visibility: hidden;
	margin-bottom: 1em;
	padding: 1em;
	background: $color-white;
	box-shadow: 0px 2px 3px rgba(37, 48, 69, 0.25);
	border-radius: 4px;
	text-transform: uppercase;
	font-size: 1rem;
	line-height: 1.6rem;
	letter-spacing: 1px;
	color: $color-mirage;
	transform: translate(-50%, 1em);
	transition: all 0.15s ease-in-out;
	z-index: 9999;
	font-family: 'Inter';

	&::before {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		width: 0;
		height: 0;
		border: 0.5em solid transparent;
		border-top-color: $color-white;
		transform: translate(-50%, 0);
	}
}
/* basiq components */

@-moz-keyframes spin {
	100% {
		-moz-transform: rotateX(180deg);
	}
}
@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotateX(180deg);
	}
}
@keyframes spin {
	100% {
		-webkit-transform: rotateX(180deg);
		transform: rotateX(180deg);
	}
}
