.confirmation-header-component {
    display: flex;
	font-weight: 600;
	border-bottom: 1px solid $color-dark-green;
	margin-bottom: 3rem;
	padding-bottom: 3rem;
	align-items: center;

	&__content-label {
		font-weight: 400;
	}

	.icon-alert-circle {
		font-size: 2.5rem;
		color: $color-red;
		margin-right: 2rem;
		margin-bottom: 0.2rem;

		&--inactive {
			color: rgba(57, 114, 108, 0.6);
		}
	}

	.icon-checkmark-outline {
		margin-right: 2rem;
	}
}