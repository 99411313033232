.container-checkbox {
	display: block;
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	/* Hide the browser's default checkbox */
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.icon-square,
	.icon-checkmark-square-2,
	.icon-minus-square {
		font-size: 2.2rem;
	}

	.icon-square {
		color: $color-dark-green;
	}

	.icon-checkmark-square-2,
	.icon-minus-square {
		color: $color-dark-green;
	}
}
