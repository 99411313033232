.profiles-page {
    &__profiles-wrapper {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: 100%;
        padding: 2.4rem 1.6rem 1.6rem 1.6rem;
        margin-bottom: 8rem;
    }

    &__profile {
        display: flex;
        align-items: center;
        padding: 0 1.6rem;
        height: 4.9rem;
        min-height: 4.9rem;
        background: $color-white;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        margin-bottom: 0.9rem;
        font-weight: 700;
        font-size: 1.4rem;
        letter-spacing: -0.02em;
        color: $color-dark-green;
        cursor: pointer;
    }

    &__no-profiles {
		font-size: 1.6rem;
		margin-top: 2.9rem;
		color: $color-dark-green;
        text-align: center;
	}

    .content-wrapper {
        padding-right: 0;

        @include respond(phone) {
            padding-right: 1.6rem;
        }

        &__bottom {
            position: absolute;
            left: 0.9rem;
            right: 0.9rem;
            bottom: 12.5rem;

            .btn-container:last-child {
                margin-left: 0;
            }
        }
    }
}