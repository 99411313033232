.input-container {
	width: 100%;
	height: 7.8rem;
	color: $color-casper;
	font-family: 'Inter';

	&__wrapper {
		position: relative;
		margin-bottom: 0.4rem;

		.inner-div {
			.file-input {
				display: none;
			}

			&__label {
				position: absolute;
				top: 0.8rem;
				left: 1.2rem;
				opacity: 0;
				transition: all 0.3s ease;
				@include inputLabel;
			}

			input {
				font-family: 'Inter';
				width: 100%;
				height: 5.6rem;
				font-size: $default-font-size;
				padding-left: 1.2rem;
				border-radius: 0.4rem 0.4rem 0 0;
				background-color: $color-white;
				border-color: transparent;
				border: none;
				color: $color-casper;
				transition: all 0.3s linear;
				-webkit-appearance: none;

				&:required,
				&:invalid {
					box-shadow: none;
				}

				&:focus {
					color: $color-calypso;
					outline: 0;

					&::placeholder {
						color: transparent;
					}
				}

				&::placeholder {
					color: $color-dark-green;
				}

				&:read-only {
					color: $color-geyser;
					cursor: not-allowed;
				}

				&:-webkit-autofill,
				&:-webkit-autofill:hover,
				&:-webkit-autofill:focus,
				&:-webkit-autofill:active {
					-webkit-box-shadow: 0 0 0 30px $color-porcelain inset !important;
					-webkit-text-fill-color: $color-calypso;
					-webkit-text-size-adjust: $default-font-size !important;
				}

				&::-webkit-textfield-decoration-container {
					margin-right: 1.4rem;
				}
			}

			input:not(:placeholder-shown) + label {
				padding-top: 0.8rem;
				transform: translateY(-0.8rem);
				opacity: 1;
			}

			&.populated {
				.inner-div__label {
					padding-top: 0.8rem;
					transform: translateY(-0.8rem);
					opacity: 1;
				}

				input {
					color: $color-calypso;
					padding-top: 2.4rem;
					padding-bottom: 0.8rem;
					padding-left: 1.2rem;
					padding-right: 1.2rem;
				}

				&::-webkit-textfield-decoration-container {
					margin-right: 5rem;
				}
			}
		}

		.icon-wrapper {
			span:first-child {
				cursor: pointer;
				font-size: 2.5rem;
				position: absolute;
				margin-top: 1.6rem;
			}
		}

		&__icon-leading {
			span:first-child {
				margin-left: 1.4rem;
			}

			.inner-div {
				input {
					padding-left: 4.8rem;
				}
				&.populated {
					input {
						padding-left: 4.8rem;
					}
				}

				&__label {
					padding-left: 3.6rem;
				}
			}
		}

		&__icon-trailing {
			span:first-child {
				right: 0;
				margin-right: 1.4rem;
			}
			.inner-div {
				input {
					padding-right: 5rem;
				}
				&.populated {
					input {
						padding-right: 5rem;
						text-overflow: ellipsis;
					}
				}
			}
		}

		img {
			border-radius: 50%;
			background: $color-porcelain;
			object-fit: cover;
			font-family: 'object-fit: cover';
		}
	}

	&__image-container {
		overflow: hidden;
		position: absolute;
		right: 1.4rem;
		top: 1.1rem;
	}

	.helper-text {
		@include inputLabel;
	}

	&--error {
		.inner-div {
			&__label {
				color: $color-cerise;
			}
		}

		span:first-child::before {
			color: $color-cerise;
		}

		.helper-text {
			color: $color-cerise;
		}
	}

	&--read-only {
		cursor: not-allowed;
		.inner-div {
			label,
			input {
				color: $color-geyser;
			}

			&.populated {
				input {
					color: $color-geyser;
					cursor: not-allowed;
				}
			}
		}
		span:first-child::before {
			color: $color-geyser;
		}
	}

	&--read-only &__wrapper,
	&--read-only .helper-text {
		pointer-events: none;
	}
}
