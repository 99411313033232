.header-component {
	position: fixed;
	display: flex;
	align-items: center;
	max-width: 38rem;
	justify-content: center;
	width: 100%;
	background-color: $background-header-color;
	height: 9.6rem;
	min-height: 9.6rem;
	border-radius: 8px 8px 0 0;
	z-index: 999;

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		position: absolute;
	}

	@include respond(phone) {
		border-radius: 0;
		max-width: 100%;
	}

	img {
		position: absolute;
		z-index: 2;
		width: 20rem;
	}

	&__button-wrapper {
		@include whiteCircle;
		position: absolute;
		left: 0rem;
		top: 3.3rem;
		margin-left: 3.2rem;
		border: none;
		outline: none;

		.icon-arrow-ios-back-outline {
			font-size: 2.2rem;
			color: $color-cyprus;
		}
	}
}
