.link-item-component {
    display: flex;
    align-items: center;
    margin: 2rem 0;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: $color-dark-green;
    cursor: pointer;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 3.5rem;
        height: 3.5rem;
        min-width: 3.5rem;
        width: 3.5rem;
        font-size: 3.5rem;
        border: 1px solid $color-dark-green;
        border-radius: 50%;
    }

    .icon-briefcase {
        font-size: 2.5rem;
    }

    label {
        text-decoration-line: underline;
		margin-left: 1.5rem;
		cursor: pointer;
    }
}