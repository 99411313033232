.btn-container {
	font-family: inherit;
	min-width: 17.6rem;
	width: 100%;
	height: 4rem;
	border-radius: 0.4rem;
	font-size: 1.4rem;
	background-color: $color-white;
	color: $color-white;
	outline: none;
	cursor: pointer;
	border: none;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	&__icon-trailing {
		justify-content: flex-start;
		padding-left: 1.5rem;
	}

	&__icon {
		position: absolute;
		left: 1.4rem;
		font-size: 2rem;
		top: 1.4rem;
	}

	&__icon-trailing &__icon {
		right: 1.2rem;
		left: auto;
	}

	&__loading {
		opacity: 0;
	}

	.icon-loader-outline {
		@include iconLoader;
	}

	.icon-svg {
		position: absolute;
		left: 1.4rem;
	}

	&:disabled {
		cursor: not-allowed;
	}

	&__primary {
		background-color: $color-calypso-vibrant;

		&:hover {
			background-color: $color-cyprus;
		}
		&:active {
			background-color: $color-calypso;
		}
		&:disabled {
			background-color: $color-porcelain;
			color: $color-casper;
		}
	}

	&__secondary-dark {
		background-color: $color-status-blue;

		&:hover {
			background-color: $color-lochmara;
		}
		&:active {
			background-color: $color-lochmara;
			color: $color-white;
		}
		&:disabled {
			background-color: $color-dark-silver;
			color: $color-casper;
		}
	}

	&__secondary-light {
		background-color: $color-status-blue;

		&:hover {
			background-color: $color-anakiwa;
			color: $color-cyprus;
		}
		&:active {
			background-color: $color-anakiwa;
			color: $color-calypso-vibrant;
		}
		&:disabled {
			border: 0.2rem solid $color-zircon;
			background-color: $color-white;
			color: $color-casper;
		}
	}

	&__tertiary {
		border: 0.2rem solid $color-status-blue;
		border-radius: 0.4rem;
		color: $color-status-blue;

		&:hover {
			background-color: $color-status-blue;
			color: $color-white;
		}
		&:active {
			background-color: $color-lochmara;
		}
		&:disabled {
			border-color: $color-zircon;
			background-color: $color-white;
			color: $color-casper;
		}
	}

	&__quaternary {
		border-radius: 0.4rem;
		color: $color-white;
		background-color: rgba(0, 45, 60, 0.9);

		@include respond(phone) {
			width: 100%;
		}

		&:hover {
			background-color: rgba(0, 45, 60, 0.8);
		}
		&:active {
			background-color: rgba(0, 45, 60, 0.8);
		}
		&:disabled {
			border: 0.2rem solid $color-zircon;
			background-color: $color-white;
			color: $color-casper;
		}
	}

	&__link {
		font-family: 'Inter';
		background-color: rgba(0, 45, 60, 0.1);
		color: $color-cyprus;

		&:hover {
			background-color: $color-zircon;
		}
		&:active {
			background-color: $color-nepal;
		}
		&:disabled {
			border: 0.2rem solid $color-zircon;
			background-color: $color-white;
		}
	}

	&__danger {
		background-color: rgba(237, 53, 86, 0.9);

		&:hover {
			background-color: #b5384e;
		}
		&:active {
			background-color: #e44561;
		}
		&:disabled {
			background-color: $color-porcelain;
			color: $color-geyser;
		}
	}
}
