.switch-component {
    &__switch-wrapper {
        @include switchComponent;
    
        .icon-loader-outline {
            @include iconLoading;
            margin-top: 0;
        }
    }

    &__button {
        width: 6rem;
        text-align: center;
        cursor: pointer;

        &--active {
            color: $color-white;
            background-color: $color-dark-green;
            border-radius: 20px;
        }
    }
}