.otp-page {
	@include respond(phone) {
		max-height: initial !important;
	}

	.content-wrapper {
		overflow: hidden;
	}

	&__otp-additional-info {
		font-size: 1.4rem;
		line-height: 1.8rem;
		text-align: center;
		letter-spacing: -0.02em;
		border-radius: 0.2rem;
		padding: 0.7rem 1.4rem;
		margin-bottom: 0.8rem;
	}

	&__error {
		@include invalidInputError;
		margin-bottom: 1rem;
	}

	&__otp-counter {
		border: 1px solid $color-dark-green;
		color: $color-dark-green;
	}

	&__resend-sms-link {
		color: $color-yellow;
		background-color: $color-dark-green;
		text-align: center;
		cursor: pointer;
		margin-right: 0.2rem;
		border-radius: 0.2rem;
		letter-spacing: -0.02em;
		border: none;
		padding: 0.3rem;

		&:disabled,
		&--disabled {
			background-color: rgba(3, 69, 64, 0.5);
		}
	}

	.input-container {
		margin-top: 1.6rem;
	}
}
