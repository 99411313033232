.footer-component {
	display: flex;
	flex-direction: column;
	background: $background-footer-color;
	width: 100%;
	padding: 1.6rem;
	line-height: 1.6rem;
	margin-top: auto;

	a {
		color: $color-brilliant-blue;
	}

	span {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 1rem;
		line-height: 1.5rem;
		color: $color-dark-green;

		&:last-child {
			a {
				margin-left: 0.2rem;
			}
		}
	}
}
