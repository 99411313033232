*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	// This defines what 1rem is
	font-size: 62.5%;
	font-family: 'Inter', sans-serif;

	.main-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		background: #e8e6df;

		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			min-height: 80rem;
		}
	}

	.main-page {
		display: flex;
		flex-direction: column;
		max-width: 38rem;
		max-height: 100vh;
		height: 100%;
		width: 100%;
		box-shadow: 0px 4px 4px rgba(166, 179, 184, 0.3);
		border-radius: 8px;
		overflow-y: hidden;
		position: relative;
		background: $background-content-color;

		@include respond(phone) {
			/* Hide scrollbar for Chrome, Safari and Opera */
			&::-webkit-scrollbar {
				display: none;
			}
			/* Hide scrollbar for IE, Edge and Firefox */
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
			max-width: 100%;
			border-radius: 0;
			box-shadow: none;
		}

		&__outer {
			position: relative;
			max-width: 38rem;
			max-height: 82rem;
			height: 100%;
			width: 100%;

			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				height: 100%;
				max-height: 100%;
				overflow-y: hidden;
			}

			@include respond(phone) {
				border-radius: 0;
				max-height: initial;
				box-shadow: none;
				max-width: 100%;
			}
		}

		&.stop-sharing-page {
			overflow-y: hidden;
		}

		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			height: 100%;
			max-height: 100%;
			overflow-y: hidden;
		}

		.content-wrapper {
			@include contentWrapper;

			&__form {
				display: flex;
				flex: 1;
				flex-direction: column;
			}

			&__top {
				font-family: Inter;
				font-style: normal;
				font-weight: normal;
				font-size: 1.4rem;
				line-height: 1.8rem;
				letter-spacing: -0.02rem;
				color: $color-dark-green;
			}

			&__bottom {
				display: flex;
				align-items: center;

				.icon-question-mark-circle {
					color: $color-red;
					font-size: 1.5rem;
					margin-right: 0.6rem;
					margin-bottom: 1.4rem;
				}
			}

			&__bottom-text span {
				margin-bottom: 0.5rem;

				&:last-child {
					a {
						color: $color-dark-green;
						font-weight: 400;
					}
				}
			}

			&__bottom-text {
				font-family: Inter;
				font-size: 1.4rem;
				line-height: 1.8rem;
				letter-spacing: -0.02rem;
				color: $color-dark-green;
			}

			&__bottom-text-flex {
				display: flex;
				flex-direction: column;
			}

			&__bottom-text-wrapper {
				display: flex;
				margin-bottom: 0.6rem;
				margin-top: auto;

				&--hidden {
					visibility: hidden;
					opacity: 0;
				}

				.icon-question-mark-circle {
					color: $color-red;
					font-size: 1.5rem;
					margin-right: 0.5rem;
				}
			}

			&__by-clicking {
				font-weight: 600;
			}
		}

		.btn-container {
			min-height: 4rem;

			span {
				font-family: Inter;
				font-style: normal;
				font-weight: 600;
				font-size: 1.8rem;
				letter-spacing: -0.02rem;

				&.icon-loader-outline {
					font-size: 3rem;
					max-width: 40px;
					margin: auto;
				}
			}

			&:first-child {
				border: 1px solid $color-dark-green;
				border-radius: 2px;
				background-color: transparent;

				span {
					color: $color-dark-green;
				}
			}

			&:last-child {
				background-color: $color-dark-green;
				margin-left: 0.9rem;

				span {
					color: $color-yellow;
				}

				&:disabled {
					background-color: $color-dark-green-opacity-50;
					border: none;

					span {
						color: $background-header-color;
					}
				}
			}

			&__disabled {
				pointer-events: none;
				border: none !important;
				background-color: rgba(3, 69, 64, 0.5) !important;

				span {
					color: $background-header-color;
				}
			}
		}

		.icon-inbox {
			font-size: 2rem;
			margin-bottom: 0.2rem;
			color: $color-dark-green;
		}

		.icon-more-horizontal-outline {
			font-size: 2.5rem;
		}
	}

	.href-icon-wrapper {
		margin-top: 0.3rem;
		text-decoration: none;
	}

	.member-page {
		.btn-container {
			&:first-child {
				margin-right: 0;
			}
		}
	}

	.btn-container {
		min-width: 10rem;
	}

	.ReactModalPortal {
		.btn-container {
			.icon-loader-outline {
				font-size: 3rem;
				max-width: 40px;
				margin: auto;
			}
		}
	}

	.error-session-page {
		.btn-container:last-child {
			margin: auto 2rem 4.3rem 2rem;
			max-width: 34rem;
			background-color: $background-content-color;
			border: 1px solid $color-dark-green;
			box-sizing: border-box;
			border-radius: 2px;

			@include respond(phone) {
				margin-top: auto;
				margin-right: auto;
				margin-left: auto;
			}

			span {
				color: $color-dark-green;
			}
		}
	}

	.input-container__wrapper {
		.inner-div {
			input {
				background: $color-white;
				color: $color-dark-green;
				font-size: 1.8rem;
				border: none;
				border-bottom: 0.2rem solid;
				border-radius: 0.4rem 0.4rem 0 0;
			}

			&.populated {
				label {
					opacity: 1;
					visibility: visible;
				}

				input {
					color: $color-dark-green;
					border-color: $color-dark-green;
				}
			}
		}

		.inner-div__label {
			opacity: 0;
			visibility: hidden;
			color: $color-dark-green;
		}
	}

	.input-container {
		&--error {
			.inner-div {
				&.populated {
					input {
						color: $color-red;
						border-color: $color-red;
					}

					label {
						color: $color-red;
					}
				}
			}
		}
	}

	.bold {
		font-weight: bold;
	}
}

@supports (font-variation-settings: normal) {
	html {
		font-family: 'Inter', sans-serif;
	}
}

body {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	.input-container {
		color: $color-dark-green;

		margin-bottom: 0.2rem;
		.inner-div {
			input {
				&::-webkit-textfield-decoration-container {
					margin-right: 1.4rem;
				}
			}
			&.populated {
				&::-webkit-textfield-decoration-container {
					margin-right: 5rem;
				}
			}
		}
	}

	input {
		&:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: $color-dark-green;
		}

		&::-ms-input-placeholder {
			/* Microsoft Edge */
			color: $color-dark-green;
		}
	}

	.btn-container {
		.icon-loader-outline {
			top: 0;
			left: 0;
			right: 0;
			font-size: 3rem;
		}
	}

	.hidden {
		display: none;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.divider {
		border: 0.1rem solid $color-dark-green;

		&--with-space {
			margin: 2.1rem 0;
		}
	}
}

body,
html {
	width: 100%;
	height: 100%;
	background: #e8e6df;

	@include respond(phone) {
		background: $background-content-color;
	}
}

#root {
	min-height: 100%;
	height: auto;
	display: flex;
	justify-content: center;
}

.info-notification {
	.info-body {
		font-size: 15px;
		padding-left: 20px;
	}
}
.success-notification {
	.success-body {
		font-size: 15px;
		padding-left: 20px;
	}
}
.error-notification {
	.error-body {
		font-size: 15px;
		padding-left: 20px;
	}
}
.loader-component {
	@include iconLoading;
}
